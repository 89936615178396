const HomePage = () => {
  return (
    <div className="home-page">
      <a href="/construction" className="construction">
        Construction
      </a>
      <a href="/agriculture" className="agriculture">
        Agricultural products
      </a>
    </div>
  )
}

export default HomePage
