const ConstructionPage = () => {
  return (
    <div>
      <section className="main-banner">
        <h1 className="banner-heading">Us Okes Construction</h1>
        <p>Building Africa's infrastructure one project at a time</p>
      </section>
      <section>
        <h5>
          The commercial division provides comprehensive design and construction
          management services to public and private clients.
        </h5>
        <h5>
          We are skilled in managing projects from conceptual planning through
          design, estimating, and construction.
        </h5>
      </section>

      <section className="sectors-banner">
        <h2 className="banner-heading">Sectors</h2>
      </section>

      <section className="services sectors">
        <div className="services-list">
          <div className="service-item">
            <h4>Residential Estates</h4>
          </div>
          <div className="service-item">
            <h4>Security Estates</h4>
          </div>
          <div className="service-item">
            <h4>Commercial Office Buildings</h4>
          </div>
          <div className="service-item">
            <h4>Warehouse Facilities</h4>
          </div>
          <div className="service-item">
            <h4>Penetentiary Facilities</h4>
          </div>
          <div className="service-item">
            <h4>Hospitals/Clinics</h4>
          </div>
          <div className="service-item">
            <h4>Schools</h4>
          </div>
        </div>
      </section>

      <section className="services-banner">
        <h2 className="banner-heading">Services</h2>
      </section>
      <section className="services">
        <div className="services-list">
          <div className="service-item">
            <h4>Construction Management</h4>
            <p>
              Programming, human resource, procurement of materials, quality
              control, health and safety
            </p>
          </div>
          <div className="service-item">
            <h4>Property Development</h4>
            <p>
              Management: Spatial and land use planning through to
              implementation of individual property development projects
            </p>
          </div>
          <div className="service-item">
            <h4>Project Management</h4>
            <p>Concept through to project closure </p>
          </div>
          <div className="service-item">
            <h4>Quantity Surveying</h4>
            <p>
              Feasibilities, cost estimating, cost control, project
              administration{' '}
            </p>
          </div>
          <div className="service-item">
            <h4>Financial Management</h4>
            <p>Cashflow management and forecasting </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ConstructionPage
