import logo from './logo.svg'
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink,
} from 'react-router-dom'
import './App.css'
import ConstructionPage from './pages/Construction'
import HomePage from './pages/Home'
import AgriculturePage from './pages/Agriculture'

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Link to="/" className="site-title">
            <img src={logo} alt="Company Logo" width="50" />
            <h4>Us Okes</h4>
          </Link>
          <nav>
            <ul>
              <li>
                <NavLink to="/construction" activeClassName="active">
                  Construction
                </NavLink>
              </li>
              <li>
                <NavLink to="/agriculture" activeClassName="active">
                  Agriculture
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>
        <div>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/construction">
              <ConstructionPage />
            </Route>
            <Route path="/agriculture">
              <AgriculturePage />
            </Route>
          </Switch>
        </div>
        <footer>
          <div>
            <Link to="/construction">Construction</Link>
            &nbsp;|&nbsp;
            <Link to="/agriculture">Agriculture</Link>
          </div>
          <p>&copy; Us Okes (PTY) LTD 2021</p>
        </footer>
      </Router>
    </div>
  )
}

export default App
