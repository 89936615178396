const AgriculturePage = () => {
  return (
    <div>
      <section className="main-banner agriculture">
        <h1 className="banner-heading">Us Okes Agriculture</h1>
        <p>Sharing Africa's finest fruits with the world</p>
      </section>
      <section className="banner citrus">Citrus</section>
      <section className="services">
        <div className="services-list">
          <div className="service-item">Valencia Oranges</div>
          <div className="service-item">Valencia Midnights Oranges</div>
          <div className="service-item">Cambria navels</div>
          <div className="service-item">Star Ruby Grape Fruits</div>
          <div className="service-item">Lemons</div>
        </div>
      </section>
      <section className="banner apples">Apples</section>
      <section className="services">
        <div className="services-list">
          <div className="service-item">Fuji</div>
          <div className="service-item">Royal Gala</div>
          <div className="service-item">Pink Lady</div>
          <div className="service-item">Granny Smith</div>
          <div className="service-item">Golden Delicious</div>
          <div className="service-item">Top Red</div>
        </div>
      </section>
      <section className="banner vegetables">Vegetables</section>
      <section className="services">
        <div className="services-list">
          <div className="service-item">Garlic</div>
          <div className="service-item">Onions</div>
        </div>
      </section>
      <section className="banner grapes">Table Grapes</section>
      <section className="banner buckwheat">Buckwheat</section>
    </div>
  )
}

export default AgriculturePage
